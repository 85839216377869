import { motion } from "framer-motion";
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdLogin, MdPersonAdd } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';
import { LOGO, SITE_NAME } from '../../helper/Constants';
import DocumentTitle from '../../helper/DocumentTitle';

const Login = () => {

    const { loginUser, isProcessing, registerUser } = useAuthContext();
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [isRegistationSuccess, setIsRegistationSuccess] = useState(false)
    const [action, setAction] = useState('Login')
    const { register, handleSubmit, formState: { errors }, watch } = useForm()

    useEffect(() => {
        if (isLoggedIn === true) {
            navigate("/", { replace: true });
        }
        // eslint-disable-next-line
    }, [isLoggedIn])

    useEffect(() => {
        if (isRegistationSuccess === true) {
            setAction('Login');
        }
    }, [isRegistationSuccess])

    const onSubmit = (data) => {
        if (action === 'Login') {
            loginUser(data.loginMobile, data.loginPassword, setIsLoggedIn);
        } else {
            delete data.loginMobile;
            delete data.loginPassword;
            delete data.confPassword;
            registerUser(data, setIsRegistationSuccess);
        }
    };

    if (action === 'Login') {
        DocumentTitle("Login");
        return (
            <div className="min-h-screen bg-gray-100 pt-28 flex flex-col justify-start px-4">
                <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                    {isProcessing &&
                        <div className="absolute w-full h-full fixed top-0 left-0 bg-white opacity-75 z-50">
                            <svg aria-hidden="true" className="absolute w-8 h-8 text-gray-200 top-2/4 left-1/2 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /></svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                    }
                    <div
                        className="absolute inset-0 bg-gradient-to-r from-myColorFrom to-myColor shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl">
                    </div>
                    <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                        <Link to='/'>
                            <motion.div whileHover={{ scale: 1.1 }} className="flex justify-center gap-2 cursor-pointer">
                                <img src={LOGO} alt="Logo" className="w-12 object-cover" />
                                <p className="text-headingColor text-xl font-bold content-center">{SITE_NAME}</p>
                            </motion.div>
                        </Link>
                        <div className="max-w-md mx-auto pt-8">
                            <div className="divide-y divide-gray-200">
                                <div className="pt-8 pb-4 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                    <form onSubmit={handleSubmit(onSubmit)} >
                                        <div className="relative">
                                            <input autoComplete="off" {...register("loginMobile", { required: "Required" })} id="loginMobile" name="loginMobile" type="number" className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Mobile Number" />
                                            <label htmlFor="loginMobile" className="absolute left-0 -top-5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Mobile Number</label>
                                            {errors.loginMobile && <p className="text-red-500 text-xs italic">Mobile Number Required</p>}
                                        </div>
                                        <div className="relative mt-12">
                                            <input autoComplete="off" {...register("loginPassword", { required: "Required" })} id="loginPassword" name="loginPassword" type="password" className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Password" />
                                            <label htmlFor="loginPassword" className="absolute left-0 -top-5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Password</label>
                                            {errors.loginPassword && <p className="text-red-500 text-xs italic">Password is Required</p>}
                                        </div>
                                        <div className="relative mt-8">
                                            <button type="submit" className="text-white inline-flex items-center bg-myColor hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-myColor dark:focus:ring-blue-800">
                                                <MdLogin className="me-1 -ms-1 w-5 h-5" />
                                                Sign In
                                            </button>
                                        </div>
                                    </form>
                                    <p className='text-sm'>Not Registered yet? <span className='text-myColor underline underline-offset-2 cursor-pointer' onClick={() => setAction('Register')}> Register Here</span> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    else {
        DocumentTitle("Register");
        return (
            <div className="min-h-screen bg-gray-100 pt-28 flex flex-col justify-start px-4">
                <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                    {isProcessing &&
                        <div className="absolute w-full h-full fixed top-0 left-0 bg-white opacity-75 z-50">
                            <svg aria-hidden="true" className="absolute w-8 h-8 text-gray-200 top-2/4 left-1/2 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /></svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                    }
                    <div
                        className="absolute inset-0 bg-gradient-to-r from-myColorFrom to-myColor shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl">
                    </div>
                    <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                        <Link to='/'>
                            <motion.div whileHover={{ scale: 1.1 }} className="flex justify-center gap-2 cursor-pointer">
                                <img src={LOGO} alt="Logo" className="w-12 object-cover" />
                                <p className="text-headingColor text-xl font-bold content-center">{SITE_NAME}</p>
                            </motion.div>
                        </Link>
                        <div className="max-w-md mx-auto pt-8">
                            <div className="divide-y divide-gray-200">
                                <div className="pt-8 pb-4 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                    <form onSubmit={handleSubmit(onSubmit)} >
                                        <div className='flex gap-6'>
                                            <div className="relative w-1/2">
                                                <input autoComplete="off" {...register("firstName", { required: "Required" })} id="firstName" name="firstName" type="text" className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="First Name" />
                                                <label htmlFor="firstName" className="absolute left-0 -top-5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">First Name</label>
                                                {errors.firstName && <p className="text-red-500 text-xs italic">First Name Required</p>}
                                            </div>
                                            <div className="relative w-1/2">
                                                <input autoComplete="off" {...register("lastName", { required: "Required" })} id="lastName" name="lastName" type="text" className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Last Name" />
                                                <label htmlFor="mobile" className="absolute left-0 -top-5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Last Name</label>
                                                {errors.lastName && <p className="text-red-500 text-xs italic">Last Name Required</p>}
                                            </div>
                                        </div>
                                        <div className="relative mt-12">
                                            <input autoComplete="off" {...register("mobile", { required: "Required", minLength: 10, maxLength: 10 })} maxLength={10}
                                                onInput={(e) => {
                                                    if (e.target.value.length > e.target.maxLength)
                                                        e.target.value = e.target.value.slice(0, e.target.maxLength);
                                                    if (!/[0-9]/.test(e.target.value))
                                                        e.target.value = e.target.value.slice(0, (e.target.value.length - 1))
                                                }} id="mobile" name="mobile" type="number" className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Mobile Number" />
                                            <label htmlFor="mobile" className="absolute left-0 -top-5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Mobile Number</label>
                                            {errors.mobile && errors.mobile.type === 'required' && <p className="text-red-500 text-xs italic">Mobile Number Required</p>}
                                            {errors.mobile && (errors.mobile.type === 'minLength' || errors.mobile.type === 'maxLength') && <p className="text-red-500 text-xs italic">Invalid Mobile Number</p>}
                                        </div>
                                        <div className='flex gap-6 mt-12'>
                                            <div className="relative">
                                                <input autoComplete="off" {...register("password", { required: "Required", minLength: 6 })} id="password" name="password" type="password" className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Password" />
                                                <label htmlFor="password" className="absolute left-0 -top-5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Password</label>
                                                {errors.password && errors.password.type === 'required' && <p className="text-red-500 text-xs italic">Password is Required</p>}
                                                {errors.password && errors.password.type === 'minLength' && <p className="text-red-500 text-xs italic">Min 6 characters</p>}
                                            </div>
                                            <div className="relative">
                                                <input autoComplete="off" {...register("confPassword", { required: "Required", validate: (val) => { if (watch('password') !== val) return false } })} id="confPassword" name="confPassword" type="password" className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Confirm Password" />
                                                <label htmlFor="confPassword" className="absolute left-0 -top-5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Confirm Password</label>
                                                {errors.confPassword && errors.confPassword.type === 'required' && <p className="text-red-500 text-xs italic">Password is Required</p>}
                                                {errors.confPassword && errors.confPassword.type === 'validate' && <p className="text-red-500 text-xs italic">Passwords Not Matching</p>}
                                            </div>
                                        </div>
                                        <div className="relative mt-8">
                                            <button type="submit" className="text-white inline-flex items-center bg-myColor hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-myColor dark:focus:ring-blue-800">
                                                <MdPersonAdd className="me-1 -ms-1 w-5 h-5" />
                                                Register
                                            </button>
                                        </div>
                                    </form>
                                    <p className='text-sm'>Already Registered? <span className='text-myColor underline underline-offset-2 cursor-pointer' onClick={() => setAction('Login')}> Login Here</span> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login;