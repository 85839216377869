import axios from "axios";
import { createContext, useContext, useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import { API } from "../helper/Constants";
import { decryptData, encryptData } from "../helper/Encryption";
import reducer from "../reducer/AuthReducer";

const AuthContext = createContext();

const initialState = {
    isProcessing: false,
    address: null,
    loginSuccess: false,
    users: []
}

const authToken = localStorage.getItem('AUTH_TOKEN');
const user = (authToken) ? decryptData(authToken) : null;

const successToast = (msg) => {
    toast.success(msg, { position: "top-center", autoClose: 3000 });
}

const errorToast = (msg) => {
    toast.error(msg, { position: "top-center", autoClose: 3000 });
}

const AuthContextProvider = ({ children }) => {

    const [state, dispatch] = useReducer(reducer, initialState);

    const loginUser = async (userName, password, setIsLoggedIn) => {
        dispatch({ type: "PROCESSING", payload: true })
        try {
            const response = await axios.post(API.LOGIN, {
                userName: userName,
                password: password
            });
            const userDetails = await response.data;
            if (response.status === 200) {
                successToast("Login Successful")
                userDetails?.role === 'ADMIN' && getAllUsers();
                const encryptedData = encryptData(userDetails);
                localStorage.setItem('AUTH_TOKEN', encryptedData);
                setIsLoggedIn(true)
                dispatch({ type: "LOGIN_SUCCESS" })
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                errorToast("Invalid User Name or Password")
                dispatch({ type: "LOGIN_FAILURE" })
            } else if (error.response && error.response.status === 401) {
                errorToast("Your Account is Not Activated")
                dispatch({ type: "LOGIN_FAILURE" })
            } else {
                errorToast("Something went wrong !!")
                dispatch({ type: "LOGIN_FAILURE" })
            }
        }
    }

    const registerUser = async (data, setIsRegistationSuccess) => {
        dispatch({ type: "PROCESSING", payload: true })
        try {
            const response = await axios.post(API.REGISTER, data);
            await response.data;
            if (response.status === 200) {
                successToast("Registration Successful")
                setIsRegistationSuccess(true)
                dispatch({ type: "LOGIN_SUCCESS" })
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                errorToast("User Already Registered")
            } else {
                errorToast("Something went wrong !!")
            }
            dispatch({ type: "LOGIN_FAILURE" })
        }
    }

    const toggleUserStatus = async (userId) => {
        dispatch({ type: "PROCESSING", payload: true })
        try {
            await axios.put(API.TOGGLE_USER_STATUS.replace('#', userId));
            getAllUsers();
            successToast("User Status Updated");
        } catch (error) {
            errorToast("Internal Server Error")
            dispatch({ type: "PROCESSING", payload: false })
        }
    }

    const getAllUsers = async () => {
        dispatch({ type: "PROCESSING", payload: true })
        try {
            const response = await axios.get(API.GET_ALL_USERS);
            const users = await response.data ? response.data : []
            dispatch({ type: "FETCH_USERS_SUCCESS", payload: users })
        } catch (error) {
            errorToast("Internal Server Error")
            dispatch({ type: "PROCESSING", payload: false })
        }
    }

    const logout = (setIsLoggedIn) => {
        localStorage.removeItem('AUTH_TOKEN');
        setIsLoggedIn(false)
        successToast("Logout Successful")
        dispatch({ type: "LOGOUT_SUCCESS" });
    }

    useEffect(() => {
        user && user?.role === 'ADMIN' && getAllUsers();
        // eslint-disable-next-line
    }, [])

    return <AuthContext.Provider value={{ ...state, loginUser, logout, dispatch, registerUser, toggleUserStatus }}>{children}</AuthContext.Provider>

}

const useAuthContext = () => {
    return useContext(AuthContext);
}

export { AuthContextProvider, useAuthContext };
