const OccasionalExpenseReducer = (state, action) => {

    switch (action.type) {
        case "PROCESSING":
            return {
                ...state,
                isProcessing: action.payload
            }

        case "FETCH_ALL_SUCCESS":
            const { occasions, occasionWiseExpenses, occasionWiseTotalExpenditure, currentExpTotal } = action.payload
            return {
                isProcessing: false,
                occasions: occasions,
                occasionWiseExpenses: occasionWiseExpenses,
                occasionWiseTotalExpenditure: occasionWiseTotalExpenditure,
                currentExpTotal: currentExpTotal
            }

        default:
            return state;
    }
}

export default OccasionalExpenseReducer;
