const BACKEND_HOST = process.env.REACT_APP_PROD_BACKEND_HOST
// const BACKEND_HOST = 'http://192.168.0.102:8081'

export const API = {

    // Daily Expense APIs
    ADD_MAIN_CATEGORY: `${BACKEND_HOST}/api/dailyExpense/addCategory`,
    UPDATE_CATEGORY: `${BACKEND_HOST}/api/dailyExpense/updateCategory`,

    ADD_DAILY_EXPENSE: `${BACKEND_HOST}/api/dailyExpense/addExpense`,
    UPDATE_DAILY_EXPENSE: `${BACKEND_HOST}/api/dailyExpense/updateExpense`,

    FETCH_DAILY_EXPENSE_DATA: `${BACKEND_HOST}/api/dailyExpense/fetchAllData`,
    GET_ALL_CATEGORIES: `${BACKEND_HOST}/api/dailyExpense/categories`,

    // Occasional Expense APIs
    ADD_OCCASION: `${BACKEND_HOST}/api/occasionalExpense/addOccasion`,
    TOGGLE_OCCASION_STATUS: `${BACKEND_HOST}/api/occasionalExpense/occasion/#/toggleStatus`,

    ADD_OCCASIONAL_EXPENSE: `${BACKEND_HOST}/api/occasionalExpense/addExpense`,
    UPDATE_OCCASIONAL_EXPENSE: `${BACKEND_HOST}/api/occasionalExpense/updateExpense`,

    FETCH_OCCASIONAL_EXPENSE_DATA: `${BACKEND_HOST}/api/occasionalExpense/fetchAllData`,
    GET_OCCASIONS: `${BACKEND_HOST}/api/occasionalExpense/occasions`,

    // Authentication APIs
    LOGIN: `${BACKEND_HOST}/api/auth/login`,
    REGISTER: `${BACKEND_HOST}/api/auth/register`,

    // User APIs
    GET_ALL_USERS: `${BACKEND_HOST}/api/auth/users`,
    TOGGLE_USER_STATUS: `${BACKEND_HOST}/api/auth/users/#/toggleStatus`,

}

export const LOGO = require('../images/expense-logo.png');
export const SITE_NAME = 'Expense Tracker';
export const PAGE_TITLE_PREFIX = SITE_NAME

export const DASHBOARD_PAGE_ROWS = 50;
export const OCCASIONAL_HOME_PAGE_ROWS = 25;

export const CATEGORY_TO_INCLUDE_IN_DETAILS = [];

