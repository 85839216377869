import React, { useEffect, useRef, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { useOccasionalExpenseContext } from '../../context/OccasionalExpenseContext';
import { OCCASIONAL_HOME_PAGE_ROWS } from '../../helper/Constants';
import DateFormat from '../../helper/DateFormat';
import DocumentTitle from '../../helper/DocumentTitle';
import Loading from '../../helper/Loading';
import PaginationHelper from '../../helper/PaginationHelper';
import PriceFormat from '../../helper/PriceFormat';
import '../../static/css/home.css';
import DashboardTemplate from '../Sidebar/Template';
import AddExpenseModal from './AddExpenseModal';
import ExpenseDetailsModal from './ExpenseDetailsModal';
import UpdateExpenseModal from './UpdateExpenseModal';

function HomePage() {

    const { occasions, occasionWiseExpenses, isProcessing } = useOccasionalExpenseContext();
    const [rowsToShow, setRowsToShow] = useState([]);
    const [startIndex, setIndex] = useState([]);
    const [occasionFilter, setOccasionFilter] = useState();
    const [selectedExpense, setSelectedExpense] = useState();
    const [filteredList, setFilteredList] = useState();
    const [search, setSearch] = useState("");
    const divRef = useRef(null);
    DocumentTitle("Occasional Expenses");

    useEffect(() => {
        divRef?.current?.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }, [rowsToShow])

    useEffect(() => {
        let filteredItems = [];
        let defaultOccasion = occasions[0]?.id;
        filteredItems = occasionFilter ? occasionWiseExpenses[occasionFilter] : occasionWiseExpenses[defaultOccasion];
        const filerSearch = filteredItems?.filter(item => (item?.details?.toLowerCase().includes(search?.toLowerCase()) || item?.amount?.toString().includes(search)))
        setFilteredList(filerSearch ? filerSearch : [])
    }, [occasionFilter, occasionWiseExpenses, occasions, search])

    if (isProcessing) {
        return (
            <>
                <div className="w-screen flex bg-gray-200">
                    <div className="app flex-1 bg-white p-6 overflow-y-hidden">
                        <Loading />
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <div className="md:flex gap-6 md:pl-6 overflow-y-scroll" ref={divRef}>
                <div className="w-full md:flex-1 md:w-1/2">
                    <div className='bg-white pb-2'>
                        <div className="w-full flex items-center justify-between">
                            <p className="text-xl text-headingColor font-bold capitalize ml-5 md:ml-10">
                                Expense Details
                            </p>
                            <span className="items-center md:w-auto md:pr-10 pr-5 bg-white">
                                <button onClick={() => document.getElementById('Add_Expense_Modal').showModal()} type="button" className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-2.5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mt-4">
                                    <MdAdd className="w-6 h-6 text-white-500 dark:text-gray-400" />
                                    <span className=' pl-2'>Add Expense</span>
                                </button>
                            </span>
                        </div>
                        <div>
                            <hr className="w-40 h-1 mx-auto border-0 rounded bg-cust-red md:ml-10 ml-5" />
                        </div>
                    </div>
                    <div className='grid grid-cols-12 gap-4 px-4 lg:px-8 py-2 bg-white'>
                        <div className='col-span-5 md:col-span-2'>
                            <select
                                id="filterOccasion"
                                onInput={(e) => setOccasionFilter(e.target.value)}
                                className="border border-gray-300 bg-white text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            >
                                {occasions?.map((occasion, index) => (
                                    <option key={index} value={occasion.id}>{occasion.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-span-7 md:col-span-2 relative">
                            <div className="relative">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input type="search" id="default-search"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    className="pl-11 border border-gray-300 bg-white text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                    placeholder="Search Expense" />
                            </div>
                        </div>
                    </div>
                    <div className="md:block overflow-x-auto scrollbar-x-hidden sm:-mx-6 md:mx-0 bg-white">
                        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                            <div className="">
                                <table className="min-w-full text-left text-sm font-light text-surface dark:text-white bg-white">
                                    <thead className="border-b border-neutral-400 font-medium dark:border-white/10">
                                        <tr>
                                            <th scope="col" className="px-4 md:px-6 py-4">
                                                Sr.
                                            </th>
                                            <th scope="col" className="px-4 md:px-6 py-4">
                                                Date
                                            </th>
                                            <th scope="col" className="px-4 md:px-6 py-4">
                                                Expense Details
                                            </th>
                                            <th scope="col" className="px-4 md:px-6 py-4">
                                                Amount
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rowsToShow?.map((expense, index) => (
                                            <tr key={index} onClick={() => {
                                                setSelectedExpense(expense);
                                                document.getElementById('expense_details_modal').showModal()
                                            }} className="cursor-pointer border-b cursor-default border-neutral-300 transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-white/10 dark:hover:bg-neutral-600">
                                                <td className="whitespace-nowrap px-4 md:px-6 py-4 font-medium">{startIndex + (index + 1)}</td>
                                                <td className="whitespace-nowrap px-4 md:px-6 py-4 font-medium"><DateFormat date={expense?.date} format={'DD MMM YYYY'} /></td>
                                                <td className="md:whitespace-nowrap px-4 md:px-6 py-4 font-medium md:max-w-20 md:break-all" >{expense?.details}</td>
                                                <td className="whitespace-nowrap px-4 md:px-6 py-4 font-medium"><PriceFormat price={expense?.amount} /></td>
                                            </tr>
                                        ))}
                                        {rowsToShow?.length === 0 &&
                                            <tr><td colSpan={4} className='font-medium text-center md:text-lg py-4'>No items to display</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <PaginationHelper filteredItems={filteredList} setRowsToShow={setRowsToShow} setIndex={setIndex} rowsLimit={OCCASIONAL_HOME_PAGE_ROWS} />
                        </div>
                    </div>
                </div>
            </div>
            <AddExpenseModal />
            <ExpenseDetailsModal expense={selectedExpense} />
            <UpdateExpenseModal expense={selectedExpense} />
        </>
    )

}

const Home = () => {
    return <DashboardTemplate MainSection={HomePage()} Page={"Expenses"} Heading={"Occasional"} />
}

export default Home