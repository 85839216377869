const DailyExpenseReducer = (state, action) => {

    switch (action.type) {
        case "PROCESSING":
            return {
                ...state,
                isProcessing: action.payload
            }

        case "FETCH_ALL_SUCCESS":
            const { allExpenses, allCategories, currentMonthExpenses, monthlyExpenses, monthlyExpenseInfos, avgMonthlyExpenditure } = action.payload
            return {
                isProcessing: false,
                allExpenses: allExpenses,
                allCategories: allCategories,
                currentMonthExpenses: currentMonthExpenses,
                monthlyExpenses: monthlyExpenses,
                monthlyExpenseInfos: monthlyExpenseInfos,
                avgMonthlyExpenditure: avgMonthlyExpenditure
            }

        case "SET_CATEGORIES":
            return {
                ...state,
                isProcessing: false,
                allCategories: action.payload
            }

        case "SET_EXPENSES":
            return {
                ...state,
                isProcessing: false,
                allExpenses: action.payload
            }

        case "SET_CURRENT_MONTH_EXP_AMOUNT":
            const curMonthExpAmount = state.currentMonthExpenses.reduce(function (prev, current) {
                return prev + +current.amount
            }, 0);
            return {
                ...state,
                curMonthExpAmount: curMonthExpAmount
            }

        case "SET_SELECTED_CATEGORY":
            return {
                ...state,
                selectedCategory: action.payload
            }

        default:
            return state;
    }
}

export default DailyExpenseReducer;
