import React from 'react';
import { MdCalculate } from 'react-icons/md';
import { useDailyExpenseContext } from '../../context/DailyExpenseContext';
import DocumentTitle from '../../helper/DocumentTitle';
import Loading from '../../helper/Loading';
import PriceFormat from '../../helper/PriceFormat';
import '../../static/css/home.css';
import DashboardTemplate from '../Sidebar/Template';

function MonthlyReportPage() {

    const { monthlyExpenseInfos, avgMonthlyExpenditure, isProcessing } = useDailyExpenseContext();
    DocumentTitle("Monthly Report");

    if (isProcessing) {
        return (
            <>
                <div className="w-screen flex bg-gray-200">
                    <div className="app flex-1 bg-white p-6 overflow-y-hidden">
                        <Loading />
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <div className="md:flex gap-6 md:pl-6 max-h-[70vh] md:max-h-[80vh]">
                <div className="w-full md:flex-1 md:w-1/2">
                    <div className='bg-white pb-2'>
                        <div className="w-full flex items-center justify-center py-4">
                            <p className="text-xl text-headingColor font-bold capitalize">
                                Monthly Expense Details
                            </p>
                        </div>
                        <div>
                            <hr className="w-52 h-1 mx-auto border-0 rounded bg-cust-red" />
                        </div>
                    </div>
                    <div className='grid grid-cols-12 gap-4 px-4 lg:px-8 py-2'>
                        <div className="col-span-8 md:col-span-4">
                            <div className="flex flex-row bg-white shadow-sm rounded px-4 py-2">
                                <div className="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-green-100 text-green-500">
                                    <MdCalculate className="w-8 h-8" />
                                </div>
                                <div className="flex flex-col flex-grow ml-4 justify-center">
                                    <div className="text-sm text-gray-500">Average Expenditure</div>
                                    <div className="font-bold text-lg"><PriceFormat price={avgMonthlyExpenditure} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="md:block overflow-x-auto scrollbar-x-hidden sm:-mx-6 md:mx-0 bg-white">
                        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                            <div className="">
                                <table className="min-w-full text-left text-sm font-light text-surface dark:text-white bg-white">
                                    <thead className="border-b border-neutral-400 font-medium dark:border-white/10">
                                        <tr>
                                            <th scope="col" className="px-4 md:px-6 py-4">
                                                Sr.
                                            </th>
                                            <th scope="col" className="px-4 md:px-6 py-4">
                                                Month
                                            </th>
                                            <th scope="col" className="px-4 md:px-6 py-4">
                                                Expenditure
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {monthlyExpenseInfos?.map((info, index) => (
                                            <tr key={index} className="border-b cursor-default border-neutral-300 transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-white/10 dark:hover:bg-neutral-600">
                                                <td className="whitespace-nowrap px-4 md:px-6 py-4 font-medium">{index + 1}</td>
                                                <td className="whitespace-nowrap px-4 md:px-6 py-4 font-medium">{info.month}</td>
                                                <td className="whitespace-nowrap px-4 md:px-6 py-4 font-medium"><PriceFormat price={info.totalExpenseAmount} /></td>
                                            </tr>
                                        ))}
                                        {monthlyExpenseInfos?.length === 0 &&
                                            <tr><td colSpan={4} className='font-medium text-center md:text-lg py-4'>No items to display</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )

}

const MonthlyReport = () => {
    return <DashboardTemplate MainSection={MonthlyReportPage()} Page={"Monthly Report"} Heading={"Daily"} />
}

export default MonthlyReport