import axios from "axios";
import { createContext, useContext, useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import { API } from "../helper/Constants";
import reducer from "../reducer/DailyExpenseReducer";
import { decryptData } from "../helper/Encryption";
import { useAuthContext } from "./AuthContext";

const DailyExpenseContext = createContext();

const initialState = {
    isProcessing: false,
    allExpenses: [],
    allCategories: [],
    currentMonthExpenses: [],
    monthlyExpenses: [],
    selectedCategory: {},
    monthyExpenseInfos: [],
    avgMonthlyExpenditure: 0,
    curMonthExpAmount: 0
}

const successToast = (msg) => {
    toast.success(msg, { position: "top-center", autoClose: 3000 });
}

const errorToast = (msg) => {
    toast.error(msg, { position: "top-center", autoClose: 3000 });
}

const DailyExpenseContextProvider = ({ children }) => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const { loginSuccess } = useAuthContext();

    const authToken = localStorage.getItem('AUTH_TOKEN');
    const user = (authToken) ? decryptData(authToken) : null;

    const fetchAllData = async () => {
        dispatch({ type: "PROCESSING", payload: true })
        try {
            const response = await axios.get(API.FETCH_DAILY_EXPENSE_DATA);
            const allExpenses = await response.data.allExpenses ? response.data.allExpenses : [];
            const allCategories = await response.data.allCategories ? response.data.allCategories : [];
            const currentMonthExpenses = await response.data.currentMonthExpenses ? response.data.currentMonthExpenses : [];
            const monthlyExpenses = await response.data.monthlyExpenses ? response.data.monthlyExpenses : [];
            const monthlyExpenseInfos = await response.data.monthlyExpenseInfos ? response.data.monthlyExpenseInfos : [];
            const avgMonthlyExpenditure = await JSON.parse(JSON.stringify(monthlyExpenseInfos)).slice(1).map(obj => obj.totalExpenseAmount).reduce((sum, val, _, arr) => sum + val / arr.length, 0);
            if (response.status === 200) {
                dispatch({ type: "FETCH_ALL_SUCCESS", payload: { allExpenses, allCategories, currentMonthExpenses, monthlyExpenses, monthlyExpenseInfos, avgMonthlyExpenditure } })
            } else {
                errorToast("Internal Server Error")
                dispatch({ type: "PROCESSING", payload: false })
            }
        } catch (error) {
            errorToast("Internal Server Error")
            dispatch({ type: "PROCESSING", payload: false })
        }
    }

    const addCategory = async (category) => {
        if (!category) {
            return
        }
        dispatch({ type: "PROCESSING", payload: true })
        try {
            await axios.post(API.ADD_MAIN_CATEGORY, { "name": category, "user": `${user?.firstName} ${user?.lastName}` });
            fetchCategories();
            successToast("New Category Added");
        } catch (error) {
            errorToast("Internal Server Error")
            dispatch({ type: "PROCESSING", payload: false })
        }
    }

    const addExpense = async (data) => {
        if (!data) {
            return
        }
        dispatch({ type: "PROCESSING", payload: true })
        try {
            await axios.post(API.ADD_DAILY_EXPENSE, { ...data, "user": `${user?.firstName} ${user?.lastName}` });
            fetchAllData();
            successToast("New Expense Added");
        } catch (error) {
            errorToast("Internal Server Error")
            dispatch({ type: "PROCESSING", payload: false })
        }
    }

    const updateExpense = async (data) => {
        if (!data) {
            return
        }
        dispatch({ type: "PROCESSING", payload: true })
        try {
            await axios.put(API.UPDATE_DAILY_EXPENSE, data);
            fetchAllData();
            successToast("Expense Details Updated");
        } catch (error) {
            errorToast("Internal Server Error")
            dispatch({ type: "PROCESSING", payload: false })
        }
    }

    const fetchCategories = async () => {
        try {
            const categoryRes = await axios.get(API.GET_ALL_CATEGORIES);
            const categories = await categoryRes.data;
            dispatch({ type: "SET_CATEGORIES", payload: categories })
        } catch (error) {
            // errorToast("Operation Failed !!");
        }
    }

    const updateCategory = async (data) => {
        try {
            const subCats = data.subCategories.map(el => (el.id ? el : { ...el, "createdBy": `${user?.firstName} ${user?.lastName}` }))
            await axios.put(API.UPDATE_CATEGORY, { ...data, "subCategories": subCats });
            fetchCategories();
            successToast("Sub-Category Added");
        } catch (error) {
            errorToast("Internal Server Error")
            dispatch({ type: "PROCESSING", payload: false })
        }
    }

    const setSelectedCategory = (category) => {
        dispatch({ type: "SET_SELECTED_CATEGORY", payload: category })
    }

    const setCurMonthExpAmount = () => {
        dispatch({ type: "SET_CURRENT_MONTH_EXP_AMOUNT" })
    }

    useEffect(() => {
        (user || loginSuccess) && fetchAllData();
        // eslint-disable-next-line
    }, [loginSuccess])

    useEffect(() => {
        setCurMonthExpAmount();
    }, [state.currentMonthExpenses])

    return <DailyExpenseContext.Provider value={{ ...state, fetchAllData, addCategory, updateCategory, setSelectedCategory, addExpense, updateExpense }}>{children}</DailyExpenseContext.Provider>

}

const useDailyExpenseContext = () => {
    return useContext(DailyExpenseContext);
}

export { DailyExpenseContextProvider, useDailyExpenseContext };
