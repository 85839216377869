import { CATEGORY_TO_INCLUDE_IN_DETAILS } from "./Constants";

const FILLER = " | ";

const ExpenseDetailsFormat = ({ category, subCategory, details }) => {
    let formattedDetails = "";
    formattedDetails = CATEGORY_TO_INCLUDE_IN_DETAILS.includes(category) ? category : formattedDetails;
    formattedDetails = "Other".toUpperCase() === subCategory.toUpperCase() ? formattedDetails : (formattedDetails ? formattedDetails.concat(FILLER, subCategory) : formattedDetails.concat(subCategory));
    formattedDetails = details ? (formattedDetails ? formattedDetails.concat(FILLER, details) : formattedDetails.concat(details)) : formattedDetails;
    return formattedDetails;
}

export default ExpenseDetailsFormat