import React, { useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { useDailyExpenseContext } from '../../context/DailyExpenseContext';
import DocumentTitle from '../../helper/DocumentTitle';
import '../../static/css/home.css';
import DashboardTemplate from '../Sidebar/Template';
import UpdateCategoryModal from './UpdateCategoryModal';

function CategoriesContent() {

    const { allCategories, addCategory, setSelectedCategory } = useDailyExpenseContext();
    DocumentTitle("Categories");

    const [newCat, setNewCat] = useState("");

    return (
        <>
            <div className="md:flex gap-6 md:pl-6">
                <div className="w-full md:w-1/2">
                    <div className='bg-white pb-2'>
                        <div className="w-full flex items-center justify-between">
                            <p className="text-xl text-headingColor font-bold capitalize ml-5 md:ml-10">
                                Categories
                            </p>
                            <span className="items-center md:w-auto md:pr-10 pr-5 bg-white">
                                <button onClick={() => document.getElementById('Add_Category_Modal').showModal()} type="button" className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-2.5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mt-4">
                                    <MdAdd className="w-6 h-6 text-white-500 dark:text-gray-400" />
                                    <span className=' pl-2'>Add Category</span>
                                </button>
                            </span>
                        </div>
                        <div>
                            <hr className="w-32 h-1 mx-auto border-0 rounded bg-cust-red md:ml-10 ml-5" />
                        </div>
                    </div>
                    <div className="overflow-x-auto scrollbar-x-hidden sm:-mx-6 md:mx-0 max-h-[75vh] bg-white">
                        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                            <div className="overflow-hidden">
                                <table className="min-w-full text-left text-sm font-light text-surface dark:text-white bg-white">
                                    <thead className="border-b border-neutral-400 font-medium dark:border-white/10">
                                        <tr>
                                            <th scope="col" className="px-4 md:px-6 py-4">
                                                Sr.
                                            </th>
                                            <th scope="col" className="px-4 md:px-6 py-4">
                                                Category
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allCategories.map((category, index) => (
                                            <tr key={index}
                                                onClick={() => {
                                                    setSelectedCategory(category);
                                                    document.getElementById('Add_SubCategory_Modal').showModal()
                                                }}
                                                className="cursor-pointer border-b border-neutral-300 transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-white/10 dark:hover:bg-neutral-600">
                                                <td className="whitespace-nowrap px-4 md:px-6 py-4 font-medium">{index + 1}</td>
                                                <td className="whitespace-nowrap px-4 md:px-6 py-4 font-medium">{category.name}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {addCategoryModal(newCat, setNewCat, addCategory)}
                <UpdateCategoryModal />
            </div>
        </>
    )

}

function addCategoryModal(newCat, setNewCat, addCategory) {
    return (
        <dialog id="Add_Category_Modal" className="modal">
            <div className="modal-box bg-white w-fit self-start mt-[25%] md:self-center md:mt-0">
                <form method="dialog">
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>

                    <h3 className="font-bold text-lg text-center mt-5">Add New Category</h3>
                    <div className='mt-5'>
                        <input type='text' onChange={(e) => setNewCat(e.target.value)} value={newCat} className="py-4 block pt-2 pb-2 ps-2 text-sm text-gray-900 border border-gray-300 rounded-lg w-60 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder='Enter Category Name' />
                    </div>
                    <div className='mt-5 text-center'>
                        <button data-modal-hide="confirm_modal" onClick={() => {
                            addCategory(newCat)
                            setNewCat("")
                        }} className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </dialog>
    )
}

const Categories = () => {
    return <DashboardTemplate MainSection={CategoriesContent()} Page={"Categories"} Heading={"Daily"} />
}

export default Categories