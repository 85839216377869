import React, { useEffect, useRef } from 'react';
import { MdClose, MdEdit } from 'react-icons/md';
import PriceFormat from '../../helper/PriceFormat';
import DateFormat from '../../helper/DateFormat';

const ExpenseDetailsModal = ({ expense }) => {
    const divRef = useRef(null);
    useEffect(() => {
        divRef.current.scroll({
            top: 0,
        });
    })

    return (
        <>
            <dialog id="expense_details_modal" className="modal">
                <div className="relative p-4 w-full max-w-md max-h-full md:max-w-2xl" ref={divRef}>
                    {/* Modal content */}
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        {/* Modal header */}
                        <div className="items-center py-4 pr-4 pl-2 md:p-5 border-b rounded-t dark:border-gray-600 grid grid-cols-12">
                            <h3 className="text-xl font-semibold dark:text-white col-span-8">
                                Expense Details
                            </h3>
                            <form method="dialog" className='col-start-9 md:col-start-10 col-span-1'>
                                <div id="close_expense_details_modal" onClick={() => {
                                    document.getElementById('Update_Expense_Modal').showModal()
                                    document.getElementById('expense_details_modal').close()
                                }}
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                    <MdEdit className="w-6 h-6" />
                                </div>
                            </form>
                            <form method="dialog" className='col-start-12 col-span-1'>
                                <button id="close_expense_details_modal"
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                    <MdClose className="w-6 h-6" />
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </form>
                        </div>
                        {/* Modal body */}
                        <div className="md:block sm:-mx-6 md:mx-0 bg-white">
                            <div className="inline-block min-w-full py-2 px-2 lg:px-8">
                                <div className="">
                                    <div className='grid grid-cols-12 gap-2 p-2 border-b-2'>
                                        <p className='col-span-4 md:col-span-3 font-medium'>
                                            Date
                                        </p>
                                        <p className='col-span-1 font-medium'>
                                            :
                                        </p>
                                        <p className='col-span-7 md:col-span-8'>
                                            <DateFormat date={expense?.date} format={'Do MMM YYYY'} />
                                        </p>
                                    </div>
                                    <div className='grid grid-cols-12 gap-2 p-2 border-b-2'>
                                        <p className='col-span-4 md:col-span-3 font-medium'>
                                            Occasion
                                        </p>
                                        <p className='col-span-1 font-medium'>
                                            :
                                        </p>
                                        <p className='col-span-7 md:col-span-8'>
                                            {expense?.occasion?.name}
                                        </p>
                                    </div>
                                    <div className='grid grid-cols-12 gap-2 p-2 border-b-2'>
                                        <p className='col-span-4 md:col-span-3 font-medium'>
                                            Details
                                        </p>
                                        <p className='col-span-1 font-medium'>
                                            :
                                        </p>
                                        <p className='col-span-7 md:col-span-8'>
                                            {expense?.details}
                                        </p>
                                    </div>
                                    <div className='grid grid-cols-12 gap-2 p-2 border-b-2'>
                                        <p className='col-span-4 md:col-span-3 font-medium'>
                                            Added By
                                        </p>
                                        <p className='col-span-1 font-medium'>
                                            :
                                        </p>
                                        <p className='col-span-7 md:col-span-8'>
                                            {expense?.user}
                                        </p>
                                    </div>
                                    <div className='grid grid-cols-12 gap-2 p-2 border-b-2'>
                                        <p className='col-span-4 md:col-span-3 font-medium'>
                                            Added On
                                        </p>
                                        <p className='col-span-1 font-medium'>
                                            :
                                        </p>
                                        <p className='col-span-7 md:col-span-8'>
                                            <DateFormat date={expense?.createdOn} format={'Do MMM YYYY'} />
                                        </p>
                                    </div>
                                    <div className='grid grid-cols-12 gap-2 p-2'>
                                        <p className='col-span-4 md:col-span-3 font-medium'>
                                            Amount
                                        </p>
                                        <p className='col-span-1 font-medium'>
                                            :
                                        </p>
                                        <p className='col-span-7 md:col-span-8'>
                                            <PriceFormat price={expense?.amount} />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </dialog>
        </>
    )
}

export default ExpenseDetailsModal