import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { useEffect, useState } from "react";

const Logout = () => {

    const navigate = useNavigate();
    const { logout } = useAuthContext();

    const [isLoggedIn, setIsLoggedIn] = useState(true);

    useEffect(() => {
        if (isLoggedIn === false) {
            navigate("/", { replace: true });
        } else {
            logout(setIsLoggedIn)
        }
        // eslint-disable-next-line
    }, [isLoggedIn])

}

export default Logout