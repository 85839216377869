import { decryptData } from "../helper/Encryption";

const AuthReducer = (state, action) => {

    const authToken = localStorage.getItem('AUTH_TOKEN');
    const user = (authToken) ? decryptData(authToken) : null;

    switch (action.type) {
        case "PROCESSING":
            return {
                ...state,
                isProcessing: action.payload
            }

        case "LOGIN_SUCCESS":
            return {
                loginSuccess: true,
                isProcessing: false
            }

        case "LOGIN_FAILURE":
            return {
                ...state,
                isProcessing: false
            }

        case "LOGOUT_SUCCESS":
            return {
                isProcessing: false
            }

        case "FETCH_USERS_SUCCESS":
            const users = action.payload?.filter(u => u.id !== user?.id)
            return {
                ...state,
                isProcessing: false,
                users: users
            }

        default:
            return state;
    }
}

export default AuthReducer;
