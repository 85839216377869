import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Login from "./components/Auth/Login";
import Logout from "./components/Auth/Logout";
import Home from './components/DailyExpenses';
import ScrollToTop from "./helper/ScrollToTop";
import AuthRouter from "./route/AuthRouter";
import './static/css/default.css';
import MonthlyReport from "./components/DailyExpenses/MonthlyReport";
import Categories from "./components/DailyExpenses/Categories";
import CategoryReport from "./components/DailyExpenses/CategoryReport";
import { useEffect } from "react";
import OcnlExpDashboard from './components/OccasionalExpenses';
import Occasions from "./components/OccasionalExpenses/Occasions";
import ExpenseReport from "./components/OccasionalExpenses/ExpenseReport";
import Users from "./components/Auth/Users";

function App() {
  // console.log = console.warn = console.error = () => { };

  useEffect(() => {
    // Replace the current history state to prevent going back
    window.history.replaceState(null, document.title, window.location.href);
    const handleBackButton = (event) => {
      event.preventDefault();
      // window.history.back();
      window.history.pushState(null, document.title, window.location.href);
      toast.info("Press back again to exit", { position: "bottom-center", autoClose: 3000, pauseOnFocusLoss: false });
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <ToastContainer style={{ marginTop: "5rem", paddingLeft: "1rem", paddingRight: "1rem" }} />
      <Routes>
        <Route element={<AuthRouter />}>
          <Route path='/' element={<Home />} />
          <Route path='/dailyExpenses/categories' element={<Categories />} />
          <Route path='/dailyExpenses/monthlyReport' element={<MonthlyReport />} />
          <Route path='/dailyExpenses/categoryReport' element={<CategoryReport />} />

          <Route path='/occasionalExpenses/dashboard' element={<OcnlExpDashboard />} />
          <Route path='/occasionalExpenses/occasions' element={<Occasions />} />
          <Route path='/occasionalExpenses/report' element={<ExpenseReport />} />

          <Route path='/users' element={<Users />} />
        </Route>
        <Route path='/login' element={<Login />} />
        <Route path='/auth/logout' element={<Logout />} />
      </Routes>
    </Router>
  )
}

export default App;
