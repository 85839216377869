import { MdArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";

const MenuItem = ({ menu, menuSelected, setMenuSelected, openSideBar }) => {
    if (menu?.subMenus?.length === 0) {
        return (
            <Link to={menu.link} onClick={() => setMenuSelected(menu.name)} className={`rounded-[10px] py-2.5 px-2.5 flex gap-3 text-lg font-medium hover:bg-gradient-to-tl hover:to-[#7851BD] hover:from-[#4E4BCF] hover:text-white text-[#233047] items-center truncate group justify-start ${openSideBar ? "sm:justify-start" : "sm:justify-center"} ${menuSelected === menu.name ? " bg-gradient-to-tl to-[#7851BD] from-[#4E4BCF] text-white" : ""} `}  >
                <div className="h-6 w-6">{menu?.svg}</div>
                <span className={`block ${openSideBar ? "block" : "sm:hidden "} `} >
                    {menu?.name}
                </span>
            </Link>
        )
    } else {
        return (
            <>
                <div className={`rounded-[10px] pt-2.5 pb-1 px-2.5 flex gap-3 text-lg font-medium text-[#233047] cursor-default items-center truncate group justify-start ${openSideBar ? "sm:justify-start" : "sm:justify-center"} ${menuSelected === menu.name ? " bg-gradient-to-tl to-[#7851BD] from-[#4E4BCF] text-white" : ""} `}  >
                    <div className="h-6 w-6">{menu?.svg}</div>
                    <span className={`block ${openSideBar ? "block" : "sm:hidden "} `} >
                        {menu?.name}
                    </span>
                </div>
                {menu?.subMenus?.map((subMenu, index) => (
                    <Link to={subMenu.link} onClick={() => setMenuSelected(subMenu.name)} key={index} className={`rounded-[10px] py-2 ml-7 px-1.5 flex gap-1 text-base font-medium hover:bg-gradient-to-tl hover:to-[#7851BD] hover:from-[#4E4BCF] hover:text-white text-[#233047] items-center truncate group justify-start ${openSideBar ? "sm:justify-start" : "sm:justify-center"} ${menuSelected === subMenu.name ? " bg-gradient-to-tl to-[#7851BD] from-[#4E4BCF] text-white" : ""} `}  >
                        {subMenu?.svg ?
                            <div className="h-6 w-6 mr-1">{subMenu?.svg}</div> :
                            <div className="h-6 w-6"><MdArrowRight className="w-6 h-6" /></div>
                        }
                        <span className={`block ${openSideBar ? "block" : "sm:hidden "} `} >
                            {subMenu?.name}
                        </span>
                    </Link>
                ))}
            </>
        )
    }
}

export default MenuItem;