import { Outlet, Navigate, useLocation } from 'react-router-dom'
import { decryptData } from '../helper/Encryption'

const AuthRouter = () => {
    let authToken = localStorage.getItem('AUTH_TOKEN');
    const location = useLocation();

    if (!authToken) {
        return (
            <Navigate to="/login" state={{ from: location }} replace />
        )
    }
    const userData = decryptData(authToken);
    if (!userData) {
        localStorage.removeItem('AUTH_TOKEN');
        return (
            <Navigate to="/login" state={{ from: location }} replace />
        )
    }
    return (
        <Outlet />
    )
}

export default AuthRouter